<template>
  <div style="background-color: rgba(23, 23, 38, 1);min-height: 100vh;overflow: scroll;">
    <div class="wallet_nav padding-lr-sm display_flex align-items_center">
      <div class="flex_1">
        <img src="img/backw.png" alt="" @click="goback">
      </div>
      <span class="flex_1 wallet_tit" style="color:#ffffff">现金提现</span>
      <div class="flex_1 wallet_rd">
        <span @click="goNext" style="color: #ffffff;">提现记录</span>
      </div>
    </div>
    <div class="wallet_box" style="background-image: url(img/money_bg.png);">
      <div class="money_title">余额</div>
      <div style="display: flex;justify-content: center;align-items: center;">
        <img class="hua_icon_" src="img/hua_icon.png" alt=""><span class="money_num">{{ hua_num }}</span>
      </div>
    </div>

    <div class="wallet_list">
      <div class="wallet_item display_flex align-items_center justify-content_flex-justify"
        v-for="(item, index) in datalist" :key="index">
        <div class="wallet_red display_flex align-items_center">
          <img src="img/small_red_bag.png" alt="">
          <span>{{ item.money }}元</span>
        </div>
        <div class="wallet_right display_flex align-items_center">
          <p>-</p>
          <img class="wallet_hua" src="img/hua_icon.png" alt="">
          <p style="color: #ffffff;">{{ '-' + item.flower }}</p>
          <div v-if="item.status == 1" class="wallet_ling" style="background-image: url(img/receive_unclick.png);"
            @click="goWithdraw(item.id)">
          </div>
          <div v-if="item.status == 0" class="wallet_ling" style="background-image: url(img/receive_clicked.png);"></div>
        </div>
      </div>

    </div>
    <div class="huaNodata margin-top-xl" v-if="noData">
      <img src="img/huaNodata.png" alt="">
    </div>
    <!-- 领取弹窗失败 -->
    <van-popup v-model="showToast" class="wall_fail" style="background-image: url(img/with_fail.png);">
      <p class="padding-lr-sm" v-html="textDetail"></p>
      <div>
        <img v-if="type == 1" src="img/go_Level.png" alt="" @click="goSave">
        <img v-else src="img/go_Know.png" alt="" @click="closeToast">
      </div>
    </van-popup>
    <!-- 领取弹窗成功 -->
    <div v-if="showToastSuc" class="wall_suc" style="background-image: url(img/with_suc.png);">
      <p class="padding-lr-sm" v-html="textDetail"></p>
    </div>


    <wechat-share @childFn="parentFn" />



  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { withdrawList, flowerBalance, welFareWithdraw } from "@/api/api";
import { getWithdrawList } from "@/api/levelUp";
import NavBar from '@/components/navBar.vue';
import WechatShare from '@/components/wechatShare.vue';
export default {
  name: 'huaWithDraw',
  components: {
    NavBar,
    WechatShare
  },
  data() {
    return {
      hua_num: 0,
      datalist: [],
      current_flower: '',
      showToast: false,
      textDetail: '',
      noData: false,
      type: 0,
      showToastSuc: false,
      img: '',
    };
  },
  created() {
    this.hua_num = this.$route.query.hua_num
    this.fetchData()
  },
  methods: {
    // 获取列表
    fetchData() {
      getWithdrawList().then(response => {
        this.datalist = response.data
        if (response.data.length == 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    // 花花提现
    goWithdraw(id) {
      let param = {
        id: id
      }
      welFareWithdraw(param).then(response => {
        this.textDetail = response.msg
        this.showToastSuc = true
        setTimeout(() => { this.showToastSuc = false }, 2000);
        this.fetchData()

      },
        error => {
          this.textDetail = error
          this.showToast = true
          if (error.hasOwnProperty('type')) {
            this.type = error.type
          } else {
            this.type = 0
          }
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    closeToast() {
      this.showToast = false
    },
    goback() {
      this.$router.go(-1)
    },
    goPeople() {
      let param = '收款人信息'
      this.$bridge.callhandler('payeeDetail', param, (data) => {
        // 处理返回数据
      })
    },
    goNext() {
      this.$router.push({ path: '/huaRecord' })
    },
    parentFn(url) {
      this.img = url;
    },
    goSave() {
      var img = this.img
      console.log(img)
      let str = {
        url: img
      }
      this.showToast = false
      let param = JSON.stringify(str)
      let _this = this
      this.$bridge.callhandler('doShareTask', param, (data) => {
        // 处理返回数据
      })
    },

  },
};
</script>