<template>
  <div>
    <template v-if="showData">;
      <vue-canvas-poster :widthPixels="0" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster>
    </template>

  </div>
</template>

<script>
import { getQRcode } from "@/api/api";
export default {
  name: 'wechatShare',
  props: {

  },
  data() {
    return {
      saveData: {},
      showData: false
    };
  },
  computed: {
    painting() {
      let img = this.saveData.qr_code+'?t='+new Date().getTime()
      return {
        width: `${document.documentElement.clientWidth}px`,
        height: `${document.documentElement.clientHeight}px`,
        background: require('../../public/img/saveBg.png'),
        views: [
          {
            type: 'image',
            url: `${img}`,
            css: {
              top: `${document.documentElement.clientHeight / 1.6 - 25}px`,
              left: `${document.documentElement.clientWidth / 2 - 88}px`,
              borderRadius: '0',
              width: '176px',
              height: '176px'
            }
          },
          {
            type: 'image',
            url: require('../../public/img/code_bg.png'),
            css: {
              top: `${document.documentElement.clientHeight / 1.6 + 170}px`,
              left: `${document.documentElement.clientWidth / 2 - 145}px`,
              borderRadius: '0',
              width: '290px',
              height: '48px'
            }
          },
          {
            type: "text",
            text: `邀请码  ${this.saveData.introduce_code}`,
            css: {
              top: `${document.documentElement.clientHeight / 1.6 + 180}px`,
              left: `${document.documentElement.clientWidth / 2 - 145}px`,
              width: "290px",
              textAlign: "center",
              fontSize: "22px",
              fontFamily: 'Arial-Black, Arial',
              color: "rgba(255, 255, 255, 1)",
            },
          },
          {
            type: "text",
            text: "温馨提示：若未获取邀请码，请手动输入，绑定邀请码能领取价值388金币的礼包哦",
            css: {
              top: `${document.documentElement.clientHeight / 1.6 + 230}px`,
              left: `${document.documentElement.clientWidth / 2 - 145}px`,
              width: "290px",
              textAlign: "center",
              fontSize: "12px",
              lineHeight: "20px",
              color: "#FFFFFF",
            },
          },
        ],
      }
    }
  },
  created() {
    this.getImage()
  },
  methods: {
    getImage() {
      getQRcode().then(response => {
        this.saveData = response.data
        this.showData = true
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    success(src) {
      this.$emit('childFn', src);
      this.$emit('saveData', this.saveData);

    },
    fail(err) {
      console.log('fail', err)
    },
    goSave() {
      var img = this.img
      let str = {
        url: img
      }
      let param = JSON.stringify(str)
      let _this = this
      this.$bridge.callhandler('doShareTask', param, (data) => {
        // 处理返回数据
      })
    },

  },
};
</script>

<style>
.van-nav-bar::after {
  border: none;
}
</style>
<style scoped>
.icon {
  width: 37px;
  height: 40px;
}

.right {
  width: 63px;
}
</style>